import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { ChannelTypeEnum } from '@common-src/entity-model/channel-entity';
import TemplateService from '@common-src/service/template';
import ChannelService from '@common-src/service/channel';

const InvisibleFunc = (...ChannelList) => (model:any, controlList) => {
    if (!controlList) {
        return !_.some(ChannelList, item => model.channelType === item);
    } else {
        const { options, value } = _.find(controlList, item => item.key === 'channelId');
        const selectChanneltype = _.get(_.find(options, oItem => oItem.value === value), 'channelType');
        return !_.some(ChannelList, item => selectChanneltype === item);
    }
};

export class TemplateEntityModel extends BaseEntityModel {
    static baseUrl = `${MESSAGE_BASE_REQUEST_PATH}/template`;

    templateConfig:any = {};
    channelName: string = undefined;

    @FormControl({
        label: '模板名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    templateName: string = undefined;

    @FormControl({
        label: '消息渠道',
        type: FormControlType.SELECT,
        optionsPromise: TemplateService.getChannelList,
        required: true,
        cascad: true,
        readonly: true,
        change: (_formControlList, formControl, formModel) => {
            if (formControl.options) {
                const { channelType } = _.find(formControl.options, item => item.value === formModel.channelId);
                formModel.channelType = channelType;
            }
        }
    } as FormControlOptionModel)
    channelId: string = undefined;
    channelType: ChannelTypeEnum = undefined;

    @FormControl({
        label: '消息标题',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(
            ChannelTypeEnum.WECHAT_SERVICE,
            ChannelTypeEnum.MESSAGE_CENTER,
            ChannelTypeEnum.LARGE_SCREEN,
            ChannelTypeEnum.DING_ROBOT,
            ChannelTypeEnum.FEISHU_ROBOT,
            ChannelTypeEnum.ALIYUN_SMS)
    } as FormControlTextModel)
    title: string = undefined;

    @FormControl({
        label: '消息类型',
        type: FormControlType.SELECT,
        optionsPromise: ChannelService.msgTypeList,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.FEISHU_ROBOT)
    } as FormControlTextModel)
    msgType: string = undefined;

    @FormControl({
        label: '发送方邮箱',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.EMAIL)
    } as FormControlTextModel)
    username: string = undefined;
    @FormControl({
        label: '邮箱授权码',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.EMAIL)
    } as FormControlTextModel)
    password: string = undefined;
    @FormControl({
        label: '邮件服务器地址',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.EMAIL)
    } as FormControlTextModel)
    host: string = undefined;
    @FormControl({
        label: '邮件服务器端口',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.EMAIL)
    } as FormControlTextModel)
    port: string = undefined;

    // @FormControl({
    //     label: '业务标识',
    //     type: FormControlType.TEXT,
    //     required: true,
    //     invisibleFunction: InvisibleFunc(ChannelTypeEnum.MESSAGE_CENTER)
    // } as FormControlTextModel)
    // centerId: string = undefined;

    // @FormControl({
    //     label: '业务类型',
    //     type: FormControlType.TEXT,
    //     required: true,
    //     invisibleFunction: InvisibleFunc(ChannelTypeEnum.MESSAGE_CENTER)
    // } as FormControlTextModel)
    // bizType: string = undefined;

    @FormControl({
        label: '微信模板ID',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.WECHAT_SERVICE)
    } as FormControlTextModel)
    weChatTemplateId: string = undefined;

    @FormControl({
        label: '跳转地址',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.WECHAT_SERVICE)
    } as FormControlTextModel)
    pagePath: string = undefined;

    @FormControl({
        label: '参数配置',
        type: FormControlType.FORM_DOUBLE,
        required: true,
        labelName: 'label',
        valueName: 'value',
        invisibleFunction: InvisibleFunc(
            ChannelTypeEnum.MESSAGE_CENTER,
            ChannelTypeEnum.LARGE_SCREEN,
            ChannelTypeEnum.FEISHU_ROBOT
        )
    })
    template: Array<any> = [];

    @FormControl({
        label: '参数配置',
        type: FormControlType.FORM_TRIPLE,
        required: true,
        labelName: 'weChatParamName',
        valueName: 'bindingKey',
        keyWord: 'weChatParamKey',
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.WECHAT_SERVICE)
    })
    weChatTemplate: Array<any> = [];

    @FormControl({
        label: '内容',
        type: FormControlType.TEXT_AREA,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.DING_ROBOT)
    } as FormControlTextModel)
    text: string = undefined;

    @FormControl({
        label: '简要内容',
        type: FormControlType.TEXT_AREA,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.MESSAGE_CENTER)
    } as FormControlTextModel)
    summary: string = undefined;

    @FormControl({
        label: '消息签名',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.ALIYUN_SMS)
    } as FormControlTextModel)
    signName: string = undefined;

    @FormControl({
        label: '模板码',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.ALIYUN_SMS)
    } as FormControlTextModel)
    templateCode: string = undefined;

    @FormControl({
        label: '模板内容',
        type: FormControlType.FORM_DOUBLE,
        required: true,
        labelName: 'smsParamName',
        valueName: 'bindingKey',
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.ALIYUN_SMS)
    } as FormControlTextModel)
    smsTemplate: Array<any> = [];

    @FormControl({
        label: '备注',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    remark: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '模板名称',
                dataIndex: 'templateName',
                scopedSlots: { customRender: 'name' },
                width: 400
            },
            {
                title: '消息标题',
                dataIndex: 'title',
                customRender: (_text, record) => {
                    return record.title ? record.title : '--';
                }
            },
            {
                title: '消息渠道',
                dataIndex: 'channelName'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 300
            }
        ];
    }
    toModel(json): any {
        super.toModel(json);
        this.username = this.templateConfig.username;
        this.password = this.templateConfig.password;
        this.host = this.templateConfig.host;
        this.port = this.templateConfig.port;
        this.weChatTemplateId = this.templateConfig.weChatTemplateId;
        this.template = _.filter(this.templateConfig.template, item => item.label !== '消息类型');
        this.weChatTemplate = this.templateConfig.weChatTemplate;
        this.text = this.templateConfig.text;
        this.summary = this.templateConfig.summary;
        this.pagePath = this.templateConfig.pagePath;
        this.smsTemplate = this.templateConfig.smsTemplate;
        this.signName = this.templateConfig.signName;
        this.templateCode = this.templateConfig.templateCode;
        this.msgType = _.get(_.findLast(this.templateConfig.template, ['label', '消息类型']), 'value');

        return this;
    }

    toService() {
        const data: any = super.toService();
        data.templateConfig.username = data.username;
        data.templateConfig.password = data.password;
        data.templateConfig.host = data.host;
        data.templateConfig.port = data.port;
        data.templateConfig.weChatTemplateId = data.weChatTemplateId;
        data.templateConfig.template = data.template;
        data.templateConfig.weChatTemplate = data.weChatTemplate;
        data.templateConfig.text = data.text;
        data.templateConfig.summary = data.summary;
        data.templateConfig.pagePath = data.pagePath;
        data.templateConfig.smsTemplate = data.smsTemplate;
        data.templateConfig.signName = data.signName;
        data.templateConfig.templateCode = data.templateCode;
        if (data.channelType === ChannelTypeEnum.FEISHU_ROBOT) {
            data.templateConfig.template = [...data.template, { label: '消息类型', value: data.msgType }];
        }

        delete data.username;
        delete data.password;
        delete data.host;
        delete data.port;
        delete data.weChatTemplateId;
        delete data.template;
        delete data.weChatTemplate;
        delete data.text;
        delete data.summary;
        delete data.pagePath;
        delete data.channelName;
        delete data.smsTemplate;
        delete data.signName;
        delete data.templateCode;
        delete data.msgType;
        return data;
    }
}

export class TemplateQueryModel extends QueryPageModel {
    @QueryControl({
        label: '渠道类型',
        type: QueryControlType.SELECT,
        // options: ChannelTypeList,
        optionsPromise: ChannelService.channelTypeList,
        hasAllOption: true,
        span: 6
    })
    channelType: ChannelTypeEnum = undefined;

    @QueryControl({
        label: '模板名称',
        type: QueryControlType.TEXT,
        span: 6
    })
    templateName: string = undefined;

    toService() {
        // return { params: this.name };
        return super.getParams();
    }
}
