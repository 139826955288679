import BaseCRUDQService from '@common-src/service3/base-crudq.service';
import { TemplateEntityModel, TemplateQueryModel } from '@common-src/entity-model/template-entity';
import { ChannelEntityModel } from '@common-src/entity-model/channel-entity';
import http from '@common-src/service3/http/axios';

export default class TemplateService extends BaseCRUDQService<TemplateEntityModel, TemplateQueryModel> {
    constructor(baseUrl = TemplateEntityModel.baseUrl) {
        super(baseUrl, TemplateEntityModel);
    }

    static async getChannelList():Promise<any> {
        const url = `${ChannelEntityModel.baseUrl}/list`;
        const res = await http.request<any>(
            {
                url,
                method: 'POST',
                params: {
                    limit: 1000,
                    page: 1,
                    params: {}
                }
            }
        );
        return _.map(res.items, item => ({
            name: item.channelName,
            value: item.id,
            channelType: item.channelType
        }));
    }
}
